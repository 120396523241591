import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Styled from 'styled-components'
import Header from './header'
import Img from 'gatsby-image'
import './layout.css'

const Main = Styled.div`
  width: calc(100% - 30px);
  max-width: 1280px;
  margin: 0 auto;
`
const IconContainer = Styled.div`
  width: 20%;
  max-width: 65px;
`
const Footer = Styled.footer`
  width: 100%;
  padding: 20px 0;
  border-top: 1px solid #dedede;
  .socialMedia{
    width: 96%;
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
      wordpressWpApiMenusMenusItems(slug: { eq: "menu-header" }) {
        items {
          title
          object_slug
          object_id
          wordpress_children {
            title
            object_slug
            object_id
          }
        }
      }
      etsy: file(relativePath: { eq: "etsy-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      facebook: file(relativePath: { eq: "facebook-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      instagram: file(relativePath: { eq: "instagram-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      pinterest: file(relativePath: { eq: "pinterest-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <>
      <Header menu={data.wordpressWpApiMenusMenusItems.items} siteDescription={data.site.siteMetadata.description} />
      <div
        style={{
          margin: `0 auto`,
          padding: `0px`,
        }}
      >
        <Main>{children}</Main>
        <Footer>
          <div className="socialMedia">
            <IconContainer>
              <a href="https://www.etsy.com/shop/brendabennettart" target="_blank" rel="noopener noreferrer">
                <Img fluid={data.etsy.childImageSharp.fluid} alt="Etsy Icon" />
              </a>
            </IconContainer>
            <IconContainer>
              <a href="https://www.instagram.com/brenda.bennett.art/" target="_blank" rel="noopener noreferrer">
                <Img fluid={data.instagram.childImageSharp.fluid} alt="Instagram Icon" />
              </a>
            </IconContainer>
            <IconContainer>
              <a
                href="https://www.pinterest.com/bbcurlygirl/my-artwork-original-mixed-media-art-by-brenda-benn/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img fluid={data.pinterest.childImageSharp.fluid} alt="Pinterest Icon" />
              </a>
            </IconContainer>
            <IconContainer>
              <a href="https://www.facebook.com/brendabennettart/" target="_blank" rel="noopener noreferrer">
                <Img fluid={data.facebook.childImageSharp.fluid} alt="Facebook Icon" />
              </a>
            </IconContainer>
          </div>
        </Footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
