import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Dropdown } from 'semantic-ui-react'
import Logo from '../components/image'

const LogoContainer = styled.div`
  width: 25%;
  max-width: 250px;
  margin-top: 10px;
`

const Headers = styled.header`
  width: 100%;
  .headerContainer {
    width: 92%;
    max-width: 1280px;
    margin: 0 auto 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .descContainer {
    h1 {
      font-size: calc(20px + 2vw);
      margin-bottom: 0;
    }
  }
  @media (max-width: 400px) {
    .descContainer {
      h1 {
        font-size: 20px;
      }
    }
  }
`

const Nav = styled.nav`
  width: 96%;
  max-width: 1280px;
  margin: 10px auto 0;
  display: flex;
  align-items: flex-start;
  @media (max-width: 420px) {
    flex-wrap: wrap;
  }

  .navContainer {
    width: 200px;
    position: relative;
    border-right: 1px solid #000;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    &:last-child {
      border-right: none;
    }
    @media (max-width: 420px) {
      width: 100%;
      flex-wrap: wrap;
      border-right: none;
      border-bottom: 1px solid #000;
      &:last-child {
        border: none;
      }
    }
  }

  .dropdown {
    text-align: center;
    font-weight: 700;
    margin: 0;
    padding: 10px 0;
    background: #fff;
    width: 100%;
    position: relative;
    outline: none;
    cursor: pointer;
    @media (max-width: 420px) {
      text-align: left;
      padding: 5px 0;
    }
    .menu {
      height: 0;
      position: absolute;
      top: 45px;
      padding: 5px 0 20px;
      width: 100%;
      opacity: 0;
      font-size: 6px;
      transition: all 0.25s ease-in-out;
      z-index: -1;
      &.visible {
        display: flex;
        flex-wrap: wrap;
        height: auto;
        opacity: 1;
        font-size: 18px;
        background: #fff;
        justify-content: center;
        text-align: center;
        z-index: 999;
      }
      @media (max-width: 420px) {
        top: 34px;
      }
      a {
        width: 100%;
        color: #000;
        text-decoration: none;
      }
      .item {
        width: 100%;
        transition: background-color 0.25s ease-in-out;
        padding: 10px 0;
        &:hover {
          background-color: #ededed;
        }
      }
    }
  }
`

const Header = ({ siteDescription, menu }) => (
  <Headers>
    <div className="headerContainer">
      <LogoContainer>
        <Link to="/">
          <Logo className="logo" />
        </Link>
      </LogoContainer>
      <div className="descContainer">
        <h1>{siteDescription}</h1>
      </div>
    </div>
    <Nav>
      <>
        {menu.map(item => (
          <div className="navContainer" key={item.object_id}>
            <Dropdown text={item.title}>
              <Dropdown.Menu>
                {item.wordpress_children &&
                  item.wordpress_children.map(subitem => (
                    <Link to={subitem.object_slug} key={subitem.object_id}>
                      <Dropdown.Item>{subitem.title}</Dropdown.Item>
                    </Link>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ))}
      </>
    </Nav>
  </Headers>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  siteDescription: ``,
}

export default Header
